<script>
    import Logo from '../Components/Logo.svelte';
    import Nav from '../Components/Nav.svelte';
    export let sticky = false;
</script>

<header
    id={sticky ? 'header-sticky' : 'header'}
    class="Header {sticky ? 'Header--sticky' : ''}"
>
    <div class="Header-content">
        <a href="#header" class="Header-logo">
            <Logo />
        </a>
        <span class="Navigation">
            <Nav />
            <div class="Navigation-hamburger pos-rel">
                <input type="checkbox" id="nav-toggle" class="Nav-checkbox" />
                <label for="nav-toggle" class="Nav-btn">
                    <span class="Nav-menuIcon">
                        <div class="Nav-menuIconCore" />
                    </span>
                </label>
                <span class="Navigation-bg" />
                <Nav class_="Nav--primary">
                    <a href="#header" class="Header-logo">
                        <Logo />
                    </a>
                </Nav>
            </div>
        </span>
    </div>
</header>
