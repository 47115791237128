<script>
    const featured = [
        'Atlanta Business Chronicle’s Best Places To Work.',
        'Best and Brightest Companies To Work For®',
    ];
</script>

<section id="featured" class="Section Featured" data-aos="fade-in">
    <div class="Featured-main">
        <div>
            <div class="Featured-header">
                <a
                    href="https://www.inc.com/profile/adtechnacity"
                    target="_blank"
                    rel="noopener"
                >
                    <img
                        src="./img/inc-logo-dark.svg"
                        alt="Inc. 5000"
                        style="max-width: 20rem"
                    />
                </a>
            </div>
            <ul class="Featured-list">
                <li>
                    <svg>
                        <use xlink:href="./img/sprite.svg#icon-arrow-up" />
                    </svg>
                    <div class="Featured-listTxt">
                        <h3 class="w-bold mbs txt-gradient">#1</h3>
                        <p class="body3">
                            Fastest-Growing in <br />Digital Marketing
                        </p>
                    </div>
                </li>
                <li>
                    <svg>
                        <use xlink:href="./img/sprite.svg#icon-arrow-up" />
                    </svg>
                    <div class="Featured-listTxt">
                        <h3 class="w-bold mbs txt-gradient">#218</h3>
                        <p class="body3">
                            Fastest-Growing <br />Overall
                        </p>
                    </div>
                </li>
                <li>
                    <svg>
                        <use xlink:href="./img/sprite.svg#icon-arrow-up" />
                    </svg>
                    <div class="Featured-listTxt">
                        <h3 class="w-bold mbs txt-gradient">1,829%</h3>
                        <p class="body3">Growth Rate</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="Featured-footer">
        <div class="Featured-footerContent">
            {#each featured as alt, i}
                <div>
                    <figure>
                        <img src="./img/featured/featured-{i + 1}.png" {alt} />
                    </figure>
                    <h6 class="body3">{alt}</h6>
                </div>
            {/each}
        </div>
    </div>
</section>
