<a href="#featured" class="Banner scroll-to">
    <div class="Banner-content">
        <div class="flex a-center-v">
            <img src="./img/inc-logo-dark.svg" alt="Inc. 5000" />
            <h5 class="body3">
                Featured in the Inc. 5000 as one of the fastest growing
                companies in America
            </h5>
        </div>

        <div class="flex a-center-v">
            <h3 class="mrm w-bold txt-gradient pvxs">#1</h3>
            <h5 class="body3">Fastest-Growing in Digital Marketing</h5>
        </div>
    </div>
</a>
