<script>
    const clients = [
        {
          class_: 'grow',
          img: 'honda',
          alt: 'Honda',
          size: '13.4rem'
        },
        {
            class_: 'grow flex a-center',
            img: 'walmart',
            alt: 'Walmart',
            size: '20.2rem',
        },
        {
            class_: 'grow flex a-center',
            img: 'boa',
            alt: 'Bank of America',
            size: '20.2rem',
        },
    ];
</script>

<section id="results" class="Section Results" data-aos="fade-in">
    <div class="Section-bleed flex a-center-v flex-wrap">
        <div>
            <h2 class="Section-title">Results</h2>
            <p class="Section-description">
                We do not sell media. We sell results. Just ask one of our
                amazing clients.
            </p>
        </div>

        <ul class="Results-clients flex a-spabet-h a-center-v grow flex-wrap">
            {#each clients as { class_, img, alt, size }}
                <li class={class_}>
                    <img
                        src="./img/clients/{img}.svg"
                        {alt}
                        style="width: {size}"
                        class="no-shrink"
                    />
                </li>
            {/each}
        </ul>
    </div>
</section>
