<section id="contact" class="Section Contact">
    <div class="Contact-container">
        <div>
            <h2 class="Section-title">Get in Touch.</h2>
        </div>

        <form id="contact-form" class="Contact-form">
            <div class="flex">
                <div class="Field mrm">
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="First Name*"
                        class="Input"
                        required
                    />
                </div>
                <div class="Field mbm">
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name*"
                        class="Input"
                        required
                    />
                </div>
            </div>
            <div class="flex">
                <div class="Field mrm">
                    <input
                        type="text"
                        id="job_title"
                        name="job_title"
                        placeholder="Job Title*"
                        class="Input"
                        required
                    />
                </div>
                <div class="Field mbm">
                    <input
                        type="text"
                        id="company_name"
                        name="company_name"
                        placeholder="Company Name*"
                        class="Input"
                        required
                    />
                </div>
            </div>
            <div class="flex">
                <div class="Field mrm">
                    <input
                        type="email"
                        id="company_email"
                        name="company_email"
                        placeholder="Company Email*"
                        class="Input"
                        required
                    />
                </div>
                <div class="Field Select mbm">
                    <select
                        id="company_type"
                        name="company_type"
                        class="Select"
                    >
                        <option value="" selected> Company Type </option>
                        <option value="Advertiser"> Advertiser </option>
                        <option value="Publisher">Publisher</option>
                        <option value="Media">Media</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>
            <div class="Field">
                <textarea
                    id="interest"
                    name="interest"
                    placeholder="How Can We Help You?"
                    cols="80"
                    rows="5"
                    class="Input"
                />
            </div>
            <button id="submit" type="submit" value="Send" class="Btn mtxs">
                Submit
            </button>
        </form>
    </div>
</section>
