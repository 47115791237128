<script>
    export let class_ = 'Nav--horizontal';

    const items = [
        { href: 'https://info.adtechnacity.com/careers', title: 'Careers' },
        { href: '#tech', title: 'Technology' },
        { href: '#client', title: 'Client Focus' },
        { href: '#results', title: 'Results' },
    ];
</script>

<nav class="Nav {class_}">
    <slot />
    <ul class="Nav-list">
        {#each items as { href, title }}
            <li class="Nav-item">
                <a {href} class="Nav-link">{title}</a>
            </li>
        {/each}
        <li class="Nav-item">
            <a
                href="#contact"
                class={class_ === 'Nav--horizontal' ? 'Btn' : 'Nav-link'}
                >Contact</a
            >
        </li>
    </ul>
</nav>
