<script>
    import Header from './Layout/Header.svelte';
    import Footer from './Layout/Footer.svelte';
    import Hero from './Components/Hero.svelte';
    import Banner from './Components/Banner.svelte';
    import Tech from './Components/Tech.svelte';
    import Client from './Components/Client.svelte';
    import Results from './Components/Results.svelte';
    import Featured from './Components/Featured.svelte';
    import Contact from './Components/Contact.svelte';
</script>

<Header />
<Header sticky="true" />
<main class="Main">
    <Hero />
    <Banner />
    <Tech />
    <Client />
    <Results />
    <Featured />
    <Contact />
</main>
<Footer />
