<script>
    import Logo from '../Components/Logo.svelte';

    const year = new Date().getFullYear();
    const partners = [
        { img: 'tag', alt: 'tag', style: '' },
        { img: 'iab', alt: 'iab', style: 'max-width: 45px' },
        { img: 'ias', alt: 'IAS', style: '' },
        { img: 'moat', alt: 'MOAT', style: 'max-width: 60px' },
    ];
    const nav = [
        { url: 'terms-and-conditions/59033060', title: 'Terms and Conditions' },
        { url: 'privacy-policy/59033060', title: 'Privacy Policy' },
    ];
</script>

<footer class="Footer">
    <div class="Footer-connect">
        <div class="flex a-spabet-h">
            <div>
                <h4 class="mbs">Our Industry Moves Fast</h4>
                <h5 class="body2" style="max-width: 39.1rem">
                    Sign up to remain up to date on the latest news, trends and
                    upcoming events. Hi, Impact
                </h5>

                <form
                    id="signup-form"
                    class="Footer-connectField flex a-start-v"
                >
                    <div class="Field mbni">
                        <input
                            type="email"
                            id="signup_email"
                            name="signup_email"
                            placeholder="Company Email*"
                            class="Input"
                            required
                        />
                    </div>

                    <button
                        id="signup-submit"
                        type="submit"
                        class="Btn Btn--square"
                    >
                        <svg class="icon i20s">
                            <use xlink:href="./img/sprite.svg#icon-arrow" />
                        </svg>
                    </button>
                </form>
            </div>
            <div>
                <h4 class="mbm">Connect With Us</h4>
                <a
                    href="https://www.linkedin.com/company/adtechnacity"
                    target="_blank"
                    rel="noopener"
                >
                    <img
                        src="./img/linkedin.png"
                        alt="LinkedIn"
                        style="max-width: 64px"
                    />
                </a>
            </div>
        </div>
    </div>
    <div class="Footer-docs">
        <div class="flex a-spabet-h a-center-v">
            <nav class="Nav Nav--horizontal flex a-center">
                <ul class="Nav-list">
                    {#each nav as { url, title }}
                        <li class="Nav-item">
                            <a
                                href="https://www.iubenda.com/{url}"
                                class="Nav-link iubenda-nostyle no-brand iubenda-embed"
                                {title}>{title}</a
                            >
                            <script type="text/javascript">
                                (function (w, d) {
                                    var loader = function () {
                                        var s = d.createElement('script'),
                                            tag = d.getElementsByTagName(
                                                'script'
                                            )[0];
                                        s.src =
                                            'https://cdn.iubenda.com/iubenda.js';
                                        tag.parentNode.insertBefore(s, tag);
                                    };
                                    if (w.addEventListener) {
                                        w.addEventListener(
                                            'load',
                                            loader,
                                            false
                                        );
                                    } else if (w.attachEvent) {
                                        w.attachEvent('onload', loader);
                                    } else {
                                        w.onload = loader;
                                    }
                                })(window, document);
                            </script>
                        </li>
                    {/each}
                    <li class="Nav-item">
                      <a href="https://uhc-tic-mrf.azureedge.net/public-mrf/2023-11-01/2023-11-01_ADTECHNACITY-LLC_index.json" class="Nav-link">Transparency in Coverage MRF</a>
                    </li>
                </ul>
            </nav>
            <div class="Footer-docs2 flex a-center">
                <h5 class="mvs mrl body3">Certifications and Affiliations</h5>
                <ul class="flex a-center">
                    {#each partners as { img, alt, style }}
                        <li>
                            <img src="./img/partners/{img}.png" {alt} {style} />
                        </li>
                    {/each}
                </ul>
            </div>
        </div>
    </div>
    <div class="Footer-credits">
        <div class="flex a-spabet-h a-center-v">
            <span class="Footer-logo">
                <Logo type="light" />
            </span>
            <h6>© {year} All rights reserved</h6>
        </div>
    </div>
</footer>
