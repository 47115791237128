import App from './App.svelte';

const app = new App({
    target: document.body,
});

export default app;

jQuery(document).ready(function () {
    var hamMenu = jQuery('.Navigation-hamburger');

    var headerSticky = function () {
        var clone = jQuery('#header-sticky');
        var menuItem1 = jQuery('#header-sticky .Nav-item:first-child');
        var menuItem2 = jQuery('#header-sticky .Nav-item:nth-child(2)');
        var menuItem3 = jQuery('#header-sticky .Nav-item:nth-child(3)');
        var menuItem4 = jQuery('#header-sticky .Nav-item:last-child');
        var sec1 = jQuery('#tech').offset().top;
        var sec2 = jQuery('#client').offset().top;
        var sec3 = jQuery('#results').offset().top;
        var sec4 = jQuery('#featured').offset().top;
        var mainTop = jQuery('.Main').offset().top - 15;
        var scrollPos = jQuery(window).scrollTop();
        var distance = scrollPos + clone.height();

        if (scrollPos >= mainTop) {
            clone.addClass('is-sticky');
            hamMenu.addClass('is-sticky');
        } else {
            clone.removeClass('is-sticky');
            hamMenu.removeClass('is-sticky');
        }

        if (distance >= sec1) {
            remove();
            menuItem1.addClass('is-active');
        } else {
            remove();
        }

        if (distance >= sec2) {
            remove();
            menuItem2.addClass('is-active');
        }

        if (distance >= sec3) {
            remove();
            menuItem3.addClass('is-active');
        }

        if (distance >= sec4) {
            remove();
        }

        function remove() {
            jQuery('.Nav-item.is-active').removeClass('is-active');
        }
    };

    headerSticky();
    jQuery(window).scroll(function () {
        headerSticky();
    });

    jQuery('.Nav-btn').on('click', function () {
        if (!jQuery('#nav-toggle').is(':checked')) {
            hamMenu.addClass('is-open');
        } else {
            hamMenu.removeClass('is-open');
        }
    });

    // Close the menu when link or bg is clicked
    jQuery('.Navigation a, .Navigation-bg').on('click', function () {
        jQuery('#nav-toggle').click();
    });

    // Add smooth scrolling to all links
    jQuery('.Header a, a.scroll-to').on('click', function (event) {
        var source = jQuery('#header').height() - 5;
        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== '') {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            jQuery('html, body').animate(
                {
                    scrollTop: jQuery(hash).offset().top - source,
                },
                800,
                function () {
                    // Note: commented out to get desired results with the scroll
                    // Add hash (#) to URL when done scrolling (default click behavior)
                    // window.location.hash = hash;
                }
            );
        } // End if
    });
});

AOS.init({
    offset: 50,
    delay: 50,
});

window.onload = function () {
    // CONTACT FORM
    document
        .getElementById('contact-form')
        .addEventListener('submit', function (event) {
            event.preventDefault();

            var firstName = document.getElementById('first_name');
            var lastName = document.getElementById('last_name');
            var job = document.getElementById('job_title');
            var company = document.getElementById('company_name');
            var companyEmail = document.getElementById('company_email');
            var companyType = document.getElementById('company_type');
            var interest = document.getElementById('interest');
            var btn = document.getElementById('submit');
            var lastField = interest.parentElement;

            btn.blur();
            btn.classList.add('loading');

            if (
                validEmpty(firstName.value) ||
                validEmpty(lastName.value) ||
                validEmpty(job.value) ||
                validEmpty(company.value) ||
                !validEmail(companyEmail.value)
            ) {
                [firstName, lastName, job, company].forEach(function (el) {
                    validateEmpty(el);
                });
                validateEmail(companyEmail);
                btn.classList.remove('loading');
                return;
            }

            emailjs.sendForm('gmail', 'adtechnacity_form', this).then(
                function (response) {
                    firstName.value = '';
                    lastName.value = '';
                    job.value = '';
                    company.value = '';
                    companyEmail.value = '';
                    companyType.value = '';
                    interest.value = '';

                    cleanAllMsgs();
                    message(
                        lastField,
                        '',
                        'Your message was sent successfully.'
                    );
                    btn.classList.remove('loading');
                },
                function (error) {
                    cleanAllMsgs();
                    message(
                        lastField,
                        'error',
                        'There was an error sending your message, try again.'
                    );
                    btn.classList.remove('loading');
                }
            );
        });

    // SIGNUP FORM
    document
        .getElementById('signup-form')
        .addEventListener('submit', function (event) {
            event.preventDefault();

            var input = document.getElementById('signup_email');
            var btn = document.getElementById('signup-submit');

            btn.blur();
            btn.classList.add('loading');

            if (!validEmail(input.value)) {
                cleanMsg(input.parentElement);
                error(
                    input.parentElement,
                    'Please enter a valid email address.'
                );
                btn.classList.remove('loading');
                return;
            }

            emailjs
                .sendForm('gmail', 'adtechnacity_subscription_form', this)
                .then(
                    function (response) {
                        input.value = '';

                        message(input.parentElement, '', "You're subscribed!");

                        btn.classList.remove('loading');
                    },
                    function (error) {
                        message(
                            input.parentElement,
                            'error',
                            'There was an error signing up, try again.'
                        );

                        btn.classList.remove('loading');
                    }
                );
        });
};

function validateEmpty(input) {
    cleanMsg(input.parentElement);
    if (validEmpty(input.value)) {
        error(input.parentElement, 'This field is required.');
    }
}

function validateEmail(input) {
    cleanMsg(input.parentElement);
    if (!validEmail(input.value)) {
        error(input.parentElement, 'Please enter a valid email address.');
    }
}

function error(el, msg) {
    el.classList.add('is-invalid');
    el.insertAdjacentHTML(
        'beforeend',
        '<span class="Input-msg">' + msg + '</span>'
    );
}

function message(el, type, msg) {
    cleanMsg(el);
    el.classList.add('is-visible');
    el.insertAdjacentHTML(
        'beforeend',
        '<span class="Input-msg ' +
            (type === 'error' ? 'txt-invalid' : 'txt-secondary') +
            '">' +
            msg +
            '</span>'
    );
}

function cleanMsg(el) {
    el.classList.remove('is-invalid');
    if (el.hasChildNodes()) {
        var children = el.childNodes;

        for (let i = 0; i < children.length; i++) {
            const element = children[i];
            const elementClasses = children[i].classList;

            if (
                elementClasses !== undefined &&
                elementClasses.contains('Input-msg')
            ) {
                element.remove();
            }
        }
    }
}

function cleanAllMsgs() {
    document.querySelectorAll('.Field.is-invalid').forEach(function (el) {
        el.classList.remove('is-invalid');
        el.lastChild.remove();
    });
}

function validEmpty(val) {
    return (val && val.trim()) == false;
}

function validEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
