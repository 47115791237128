<script>
    const items = [
        { alt: 'Proprietary Technology', size: '6.5rem' },
        { alt: 'Performance-Based Pricing', size: '10rem' },
        { alt: 'Premium Placements', size: '10.7rem' },
        { alt: 'Complete Transparency', size: '10rem' },
        { alt: 'Brand Safety & Suitability', size: '8.9rem' },
        { alt: 'Fraud Protection', size: '8.7rem' },
    ];
</script>

<section id="tech" class="Section">
    <div class="Section-bleed" data-aos="fade-in">
        <h2 class="Section-title">Technology</h2>
        <p class="Section-description">
            Our technology was specifically crafted to drive the KPIs that
            matter to you the most.
        </p>

        <ol class="Tiles">
            {#each items as { alt, size }, i}
                <li class="Tiles-item" data-aos="fade-in">
                    <figure class="Tiles-figure">
                        <img
                            src="./img/tech/tech-{i + 1}.svg"
                            {alt}
                            style="max-width: {size}"
                        />
                        <figcaption class="mtl body3">{alt}</figcaption>
                    </figure>
                </li>
            {/each}
        </ol>
    </div>
</section>
