<script>
    const options = [
        {
            title: 'Managed Services',
            description:
                'Our dedicated team is laser focused on campaign performance and adapting to changing market conditions, while providing expert recommendations to maximize results.',
        },
        {
            title: 'Expert People',
            description:
                'We are a team of seasoned professionals able to delve into the specific business needs of each client so that the client’s success is guaranteed. We make our clients look good.',
        },
    ];
</script>

<section id="client" class="Section Client">
    <div class="Section-bleed" data-aos="fade-in">
        <h2 class="Section-title">Client Focus</h2>
        <p class="Section-description">
            Our team will overcome obstacles, find solutions and deliver
            exceptional results.
        </p>

        <ul class="List">
            {#each options as { title, description }}
                <li>
                    <div>
                        <h4 class="mbxs">{title}</h4>
                        <p>{description}</p>
                    </div>
                </li>
            {/each}
        </ul>
    </div>
</section>
